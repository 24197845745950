<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{reset, handleSubmit}">
      <v-card>
        <v-card-title>{{ model.id ? 'Изменить пользователя системы' : 'Создать пользователя системы' }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col :cols="model.id ? 6 : 12">
              <h3 class="text-h6 mb-2 font-weight-regular text--primary">Общие данные</h3>
              <v-row>
                <v-col cols="6">
                  <validation-provider v-slot="{errors}" name="username" rules="required|max:100">
                    <v-text-field v-model="model.username"
                                  required :error-messages="errors"
                                  label="Логин"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider v-slot="{errors}" name="role" rules="required">
                    <v-select v-model="model.user_type"
                              :items="Roles"
                              item-value="id" item-text="title"
                              required :error-messages="errors" label="Роль"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="model.password"
                                  :rules="passwordRules"
                                  :type="showPassword ? 'text' : 'password'"
                                  label="Пароль" append-icon="mdi-eye" @click:append="showPassword = !showPassword"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <validation-provider v-slot="{errors}" name="last_name" >
                    <v-text-field v-model="model.last_name"
                                  required :error-messages="errors"
                                  label="Фамилия"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                <validation-provider v-slot="{errors}" name="first_name" rules="required|max:100">
                  <v-text-field v-model="model.first_name"
                                required :error-messages="errors"
                                label="Имя"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              </v-row>
            </v-col>
            <v-col v-if="model.id" offset="1" cols="5">
              <h3 class="text-h6 mb-2 font-weight-regular text--primary">Права пользователя</h3>
              <div v-for="(permission, index) in Permissions" :key="index">
                <v-divider v-if="permission.hasDivider" class="mt-4"></v-divider>
                <v-checkbox v-model="model.user_permissions[permission.key]"
                            color="success" :label="permission.title" hide-details :class="permission.hasDivider ? 'font-weight-bold' : 'ml-5'"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{ model.id ? 'Сохранить' : 'Создать' }}
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'user-form',

  data() {
    return {
      showPassword: false,
      password_confirmation: '',
    }
  },

  computed: {
    ...mapState('User', ['saving', 'model', 'Roles', 'Permissions']),

    passwordRules() {
      return this.model.id ? [
        (v) => !v || v.toString().length > 5,
      ] : [(v) => (v || '').length > 5,]
    },
  },

  methods: {
    cancel(reset) {
      reset()
      this.$emit('user:form:cancel')
    },

    save() {
      this.$store
        .dispatch('User/save')
        .then(() => {
          this.$emit('user:form:saved')
        })
    },
  },
}
</script>
