<template>
  <v-card :loading="!!fetching">
    <template #progress>
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </template>

    <v-card-title>
      Пользователь {{model|full_name}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        {{model.username}} • {{model.email}} • {{getRole(model.user_type).title}}
      </div>

      <h3 class="mt-5 mb-2 font-weight-regular text--primary">Права пользователя</h3>

      <div v-for="(permission, index) in Permissions" :key="index">
        <div v-if="(model.user_permissions || {})[permission.key]"
             :class="permission.hasDivider ? 'font-weight-bold' : 'ml-4'"
        >
          <v-icon small color="success">mdi-check</v-icon> {{ permission.title }}
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" dark rounded @click="$emit('dialog:edit', model)">
        <v-icon small left>mdi-pen</v-icon> Изменить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapGetters} = createNamespacedHelpers('User')

  export default {
    name: 'user-details',

    computed: {
      ...mapState(['fetching', 'model', 'Permissions']),
      ...mapGetters(['getRole']),
    },
  }
</script>
