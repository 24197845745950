<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5">Пользователи</span>
        <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
               @click="showForm()"
        >
          <v-icon left>mdi-plus</v-icon> Добавить
        </v-btn>
        <v-dialog v-model="formDialog" width="80vw" persistent>
          <user-form v-if="formDialog"
                            @user:form:saved="closeForm"
                            @user:form:cancel="closeForm"
          ></user-form>
        </v-dialog>
      </v-col>
      <v-col cols="2" offset="4">
        <v-select :items="Roles" v-model="filter.user_type"
                  hide-details item-value="id" item-text="title" label="Роль"
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="users"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.username}}</td>
          <td>{{item|full_name}}</td>
          <td>{{item.email}}</td>
          <td>{{getRole(item.user_type).title}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon left small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" width="60vw" v-model="detailDialog">
      <user-details @dialog:close="closeDetails"
                     @dialog:edit="editForm"
      ></user-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapGetters, mapMutations} = createNamespacedHelpers('User')

  import userDetails from '@/modules/user/details'
  import userForm from '@/modules/user/form'
  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'users-list',

    mixins: [hasFilter, hasDialog],

    customFilters: {
      user_type: null,
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
        'Roles',
      ]),
      ...mapGetters(['getRole']),

      headers() {
        return [
          {text: '#', align: 'center', width: '70'},
          {text: 'Логин', value: 'name'},
          {text: 'ФИО', value: 'name'},
          {text: 'E-mail', value: 'name'},
          {text: 'Роль'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      users() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      showForm(item) {
        if (item && item.id) {
          this.show(item.id)
        } else {
          this.setModel({})
        }
        this.formDialog = true
      },

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список пользователей')
    },

    components: {
      userForm,
      userDetails,
    }
  }
</script>
