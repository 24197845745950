var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить пользователя системы' : 'Создать пользователя системы'))]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.model.id ? 6 : 12}},[_c('h3',{staticClass:"text-h6 mb-2 font-weight-regular text--primary"},[_vm._v("Общие данные")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Логин"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.Roles,"item-value":"id","item-text":"title","required":"","error-messages":errors,"label":"Роль"},model:{value:(_vm.model.user_type),callback:function ($$v) {_vm.$set(_vm.model, "user_type", $$v)},expression:"model.user_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.passwordRules,"type":_vm.showPassword ? 'text' : 'password',"label":"Пароль","append-icon":"mdi-eye"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Фамилия"},model:{value:(_vm.model.last_name),callback:function ($$v) {_vm.$set(_vm.model, "last_name", $$v)},expression:"model.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Имя"},model:{value:(_vm.model.first_name),callback:function ($$v) {_vm.$set(_vm.model, "first_name", $$v)},expression:"model.first_name"}})]}}],null,true)})],1)],1)],1),(_vm.model.id)?_c('v-col',{attrs:{"offset":"1","cols":"5"}},[_c('h3',{staticClass:"text-h6 mb-2 font-weight-regular text--primary"},[_vm._v("Права пользователя")]),_vm._l((_vm.Permissions),function(permission,index){return _c('div',{key:index},[(permission.hasDivider)?_c('v-divider',{staticClass:"mt-4"}):_vm._e(),_c('v-checkbox',{class:permission.hasDivider ? 'font-weight-bold' : 'ml-5',attrs:{"color":"success","label":permission.title,"hide-details":""},model:{value:(_vm.model.user_permissions[permission.key]),callback:function ($$v) {_vm.$set(_vm.model.user_permissions, permission.key, $$v)},expression:"model.user_permissions[permission.key]"}})],1)})],2):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать')+" ")],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }